import { useState, useEffect } from "react";
import { Button, Typography, Box, CircularProgress } from "@mui/material";

export default function WhatsAppQR() {
  const [status, setStatus] = useState("Bağlı değil");
  const [showQR, setShowQR] = useState(false);
  const [loading, setLoading] = useState(false);

  // QR kodu oluşturma isteği
  const generateQR = async () => {
    setLoading(true);
    try {
      const response = await fetch("https://duyurular.org/api/whatsapp/generate-qr");
      const data = await response.json();
      if (data.success) {
        setShowQR(true);
      } else {
        alert("QR kodu henüz hazır değil, tekrar deneyin!");
      }
    } catch (error) {
      console.error("QR kodu oluşturulurken hata:", error);
    } finally {
      setLoading(false);
    }
  };

  // WhatsApp bağlantı durumunu kontrol et
  const checkStatus = async () => {
    try {
      const response = await fetch("https://duyurular.org/api/whatsapp/status");
      const data = await response.json();
      setStatus(data.status);
    } catch (error) {
      console.error("Durum kontrol edilirken hata:", error);
    }
  };

  // Logout işlemi
  const logout = async () => {
    try {
      await fetch("https://duyurular.org/api/whatsapp/logout");
      setStatus("Bağlı değil");
      setShowQR(false);
    } catch (error) {
      console.error("Çıkış yapılırken hata:", error);
    }
  };

  // Sayfa açıldığında bağlantı durumunu kontrol et
  useEffect(() => {
    checkStatus();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        bgcolor: "background.default",
        p: 2,
      }}
    >
      <Typography variant="h4" sx={{ mb: 2 }}>
        WhatsApp QR Kod
      </Typography>

      <Typography
        variant="h6"
        sx={{
          mb: 2,
          color: status === "Bağlı" ? "green" : "red",
        }}
      >
        Durum: {status}
      </Typography>

      {status === "Bağlı değil" && !loading && (
        <Button
          variant="contained"
          color="primary"
          onClick={generateQR}
          sx={{ mb: 2 }}
        >
          QR Oluştur
        </Button>
      )}

      {loading && <CircularProgress />}

      {showQR && status === "Bağlı değil" && (
        <Box sx={{ mt: 2 }}>
          <img
            src="https://www.duyurular.org/duyurular_images/qr.png"
            alt="WhatsApp QR Kodu"
            style={{ border: "2px solid #ccc", padding: "10px" }}
          />
        </Box>
      )}

      {status === "Bağlı" && (
        <Button
          variant="contained"
          color="error"
          onClick={logout}
          sx={{ mt: 2 }}
        >
          Logout
        </Button>
      )}
    </Box>
  );
}
